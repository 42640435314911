// import { useHistory } from 'react-router-dom'
import { useContext } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import FormContext from "../../../../../form.context"
import { connect } from "../api.utils"
export const PaypalButton = () => {
    const { form, method, setStep } = useContext(FormContext)
    
    return (
        <div>
            <PayPalScriptProvider
                options = {{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
            >
                <PayPalButtons
                    forceReRender   = {[ form ]}
                    disabled        = { Object.keys(form).length === 0 }
                    onError         = { () => {
                                        alert("Transaction was unsuccessful. Please try again.")
                                    } }
                    createOrder     = { (data, actions) => {
                                        return actions
                                                .order
                                                .create({
                                                    purchase_units: [ { amount: { value: +form.amount } } ]
                                                })
                                                .then((orderId) => orderId)
                                    } }
                    onApprove       = { async (res) => {
                            
                                        const newForm = { ...form }
                                        newForm.payment_type = method
                                        newForm.reference_number = res.orderID
                                        newForm.status = "captured"
                                        
                                        await connect().post("/api/transaction", newForm)
                                        setStep(4)
                                    } }
                    style           = {{ layout: "vertical" }}
                />
            </PayPalScriptProvider>
        </div>
    )
}