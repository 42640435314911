import { useState } from 'react'
import { LeftSection, RightSection } from "./components"

import "react-datepicker/dist/react-datepicker.css"

function App() {
  const [ step, setStep ] = useState(1)

  return (
    <div className="d-flex flex-column flex-lg-row">
      <LeftSection show = { step < 4 } />
      <RightSection
        step = { step }
        setStep = { setStep }
      />
    </div>
  )
}

export default App
