import { useState, useEffect, useContext } from 'react'
import { Input } from '../input'
import { TextArea } from '../text_area'
import { Select } from '../select'
import { FileInput } from '../file_input'
import './_styles.scss'
import controls from './controls.map'
import cardDetails from './card_details.map'
import rules from './validation.rules'
import cardRules from './card_details.rules'
import { validate } from './validation.utils'
import FormContext from '../../form.context'
import { BankOptions } from './components'
import { DatePicker } from '../date_picker'

export const PaymentInfo = ({ show }) => {
    const formContext           = useContext(FormContext)
    const { onFormChange, onCardChange, step, method } = formContext
    const [ form, setForm ]     = useState({ payment_option : "Application Fee Payment" })
    const activeControls        = step === 2 ? controls : cardDetails
    const activeRules           = step === 2 ? rules : cardRules
    const [ errors, setErrors ] = useState({})
    const handleChange          = ({ name, value }) => {
                                    setForm(prev => {
                                        const newForm = { ...prev }
                                        newForm[name] = value

                                        const validation = validate(newForm, activeRules({ ...form, method }))
                                        setErrors(prev => {
                                            return { ...prev, [name]: validation.errors[name] }
                                        })

                                        return newForm
                                    })
                                }

    useEffect(() => {
        if (step === 2) {
            const validation = validate(form, rules({ ...form, method }))
            onFormChange(validation.valid ? form : {})
        }
        else {
            const validation = validate(form, cardRules())
            onCardChange(validation.valid ? form : {})
        }
    }, [ form ])

    useEffect(() => {
        if (step === 2) setForm({ payment_option : "Application Fee Payment", ...formContext.form })
        else setForm({ ...formContext.cardDetails })
    }, [ step ])

    return show ? (
        <div className='payment-info'>
            <BankOptions />
            {
                activeControls.map(control => {
                    switch(control.type) {
                        case "select": {
                            return (!control.showOn || control.showOn({ ...form, method })) ? (
                                <Select
                                    key = { control.name }
                                    { ...control }
                                    onChange = { handleChange }
                                    value = { form[control.name] }
                                    error = { errors[control.name] }
                                />
                            ) : null
                        }
                        case "textarea": {
                            return (
                                <TextArea
                                    key = { control.name }
                                    { ...control }
                                    onChange = { handleChange }
                                    value = { form[control.name] }
                                    show  = { control.showOn(form) }
                                    error = { errors[control.name] }
                                />
                            )
                        }
                        case "file": {
                            return (!control.showOn || control.showOn({ ...form, method })) ? (
                                <FileInput
                                    key = { control.name }
                                    { ...control }
                                    onChange = { handleChange }
                                    error = { errors[control.name] }
                                />
                            ) : null
                        }
                        case "datepicker": {
                            return (!control.showOn || control.showOn({ ...form, method })) ? (
                                <DatePicker
                                    key = { control.name }
                                    { ...control }
                                    value = { form[control.name] }
                                    onChange = { handleChange }
                                    error = { errors[control.name] }
                                />
                            ) : null
                        }
                        default: return (!control.showOn || control.showOn({ ...form, method })) ? (
                            <Input
                                key = { control.name }
                                { ...control }
                                onChange = { handleChange }
                                value = { form[control.name] }
                                error = { errors[control.name] }
                            />
                        ) : null
                    }
                })
            }
        </div>
    ) : null
}