export const Header = ({ show }) => {

    return show ? (
        <div className="form-header d-flex">
            <div className="header-icon">
                <img
                    src = { require('../list-text.png') }
                />
            </div>
            <h4>
                Payment
            </h4>
        </div>
    ) : null
}