import revalidator from 'revalidator'

export function validate(object, schema, options, hideName = false){
	let errors = {}
	let validation = revalidator.validate(object, schema, options)
	if (!validation.valid) {

		validation.errors.forEach((error) => {
			errors[error.property] = `This field ${error.message}`
		})
	}

	return {
		valid: validation.valid,
		errors: errors
	}
}
