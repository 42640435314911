import { useContext, useState } from "react"
import FormContext from "../../../../form.context"
import { Select } from "../../../select"
import optionDetails from './option_details.dictionary'

export const BankOptions = () => {
    const [ bank, setBank ] = useState(0)
    const { method }        = useContext(FormContext)

    return method === "bank" ? (
        <div>
            <div className="h5">
                <strong>Step 1:</strong>
                <div style={{ fontSize: 16, marginTop: 5 }}>
                    Select a Bank to deposit.
                </div>
            </div>
            <div>
                <Select
                    name = "bank"
                    type = "select"
                    value = { bank }
                    options = {
                        [ ...Array(5) ].map((v, i) => ({ label: `Bank ${ i + 1 }`, value: i }))
                    }
                    onChange = { ({ value }) => setBank(value) }
                />
            </div>
            <div style = {{ marginTop: 5 }} >
                { optionDetails[bank] }
            </div>
            <div
                className = "h5"
                style = {{ marginTop: 20 }}
            >
                <strong>Step 2:</strong>
                <div style={{ fontSize: 16, marginTop: 5 }}>
                    If payment has already been made, fill out the form and attach the proof of payment.
                </div>
            </div>
        </div>
    ) : null
}