export const Select = ({
    className   = '',
    label       = '',
    name        = '',
    value       = '',
    onChange    = () => {},
    options     = []
}) => {

    return (
        <div
            className = {`fth-input ${ className }`}
        >
            {
                label ? (
                    <label>
                        { label } *
                    </label>       
                ) : null
            }
            <div>
                <select
                    name        = { name }
                    value       = { value }
                    onChange    = { ({ target }) => onChange({ name, value: target.value }) }
                >
                    {
                        options.map((option, index) => (
                            <option
                                key     = { `option-${ name }-${ index }`}
                                value   = { option.value }
                            >
                                { option.label }
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>
    )
}