const cardDetails = [
    {
        label: "Card Number",
        name: "card_number",
        type: "password"
    },
    {
        label: "Expiration Date",
        name: "expiration_date",
        type: "exp-date"
    },
    {
        label: "CVV",
        name: "card_security_code",
        type: "numeric",
        decimal: 0
    },
    {
        label: "Biller First Name",
        name: "biller_first_name"
    },
    {
        label: "Biller Last Name",
        name: "biller_last_name"
    },
    {
        label: "Biller Address",
        name: "biller_address1"
    },
    {
        label: "Biller City",
        name: "biller_city"
    },
    {
        label: "Biller Country",
        name: "biller_country"
    },
    {
        label: "Biller Postal Code",
        name: "biller_postal_code"
    }
]

export default cardDetails