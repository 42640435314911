const controls = [
    {
        label: "Payee Name",
        name: "payee_name",
        showOn : form => form.method === "bank"
    },
    {
        label: "Student Name",
        name: "name"
    },
    {
        label: "Account/Student Number",
        name: "account_number",
        type: "numeric",
        decimal: 0
    },
    {
        label: "Grade/Level",
        name: "grade",
        type: "numeric",
        decimal: 0
    },
    {
        label: "Amount ($)",
        name: "amount",
        type: "numeric"
    },
    {
        label: "Email Address",
        name: "email"
    },
    {
        label: "Contact Number",
        name: "contact_number",
        type: "number-string",
        decimal: 0
    },
    {
        label: "Payment Type",
        name: "payment_option",
        type: "select",
        options: [
            { label: "Application Fee Payment", value: "Application Fee Payment" },
            { label: "Buy a School Photo", value: "School Photo" },
            { label: "Make a Donation", value: "Donation" },
            { label: "Other Payment", value: "Other" },
            { label: "School Trip Payment", value: "School Trip Payment" },
            { label: "Transcript Fee Payment", value: "Transcript Fee Payment" },
            { label: "Tuition & Fees Payment", value: "Tuition & Fees Payment" }
        ]
    },
    {
        label: "Payment Details",
        name: "payment_details",
        type: "textarea",
        showOn : form => form.payment_option === "Other"
    },
    {
        label: "Proof of Payment Attachment",
        name: "attachment",
        type: "file",
        showOn : form => form.method === "bank"
    },
    {
        label: "Date Deposited",
        name: "date_deposit",
        type: "datepicker",
        showOn : form => form.method === "bank"
    }
]

export default controls