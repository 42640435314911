export const FileInput = ({
    className   = '',
    label       = '',
    name        = '',
    onChange    = () => {},
    error       = ''
}) => {

    const handleChange = (target) => {
        onChange({ name, value: target.files.length ? target.files[0] : null })
    }

    return (
        <div
            className = {`fth-input ${ className }`}
        >
            <label>
                { label } *
            </label>
            <div>
                <input
                    name        = { name }
                    onChange    = { ({ target }) => handleChange(target) }
                    type        = "file"
                />
            </div>
            <div
                className="text-danger"
                style={{ fontSize: 12, fontWeight: "bold" }}
            >
                { error }
            </div>
        </div>
    )
}