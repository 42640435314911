export const TextArea = ({
    className   = '',
    label       = '',
    name        = '',
    value       = '',
    onChange    = () => {},
    show        = true
}) => {

    return show ? (
        <div
            className = {`fth-input ${ className }`}
        >
            <label>
                { label } *
            </label>
            <div>
                <textarea
                    name        = { name }
                    value       = { value }
                    onChange    = { ({ target }) => onChange({ name, value: target.value }) }
                >
                </textarea>
            </div>
        </div>
    ) : null
}