import ReactDatePicker from "react-datepicker"

export const DatePicker = ({
    className   = '',
    label       = '',
    name        = '',
    value       = '',
    onChange    = () => {},
    error       = ''
}) => {

    console.log(value)
    return (
        <div
            className = {`fth-input ${ className }`}
        >
            <label>
                { label } *
            </label>
            <div>
                <ReactDatePicker
                    selected    = { value || new Date() }
                    onChange    = { (date) => onChange({ name, value: date }) }
                />
            </div>
            <div
                className="text-danger"
                style={{ fontSize: 12, fontWeight: "bold" }}
            >
                { error }
            </div>
        </div>
    )
}