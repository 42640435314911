import check from './check.png'
import './_style.scss'

export const SuccessPage = ({ show }) => {

    return show ? (
        <div id = "successPage">
            <img
                alt = "check"
                src = { check }
                className = "check-icon"
            />
            <div className='h1'>
                Payment Successful!
            </div>
            <div className='p'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mauris enim, hendrerit id lectus non, auctor bibendum purus. Donec non auctor turpis, at ullamcorper metus. Duis malesuada dolor sed nibh ullamcorper tempor. Phasellus finibus purus at sem consequat, aliquet lobortis nulla semper. 
            </div>
            <a href = "/">
                Back to Site
            </a>
            <button
                onClick = { () => {
                    window.location.reload()
                }}
            >
                Make another transaction
            </button>
        </div>
    ) : null
}