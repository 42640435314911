export const Input = ({
    className   = '',
    label       = '',
    name        = '',
    value       = '',
    onChange    = () => {},
    error       = '',
    type        = 'text',
    decimal     = 2,
    min,
    max
}) => {

    const handleChange = (target, blurred = false) => {
        switch (type) {
            case "numeric": {
                let value = target.value.replace(/[^\d.]/g, "").replace(/,/g, '')
                if ((isNaN(value) || value.charAt(0) === '0') && value) value = parseFloat(value)
                
                if (max && (+value > +max)) value = max
                if  (decimal === 0 && value) value = parseInt(value)
    
                value = value.toString()
    
                if (value && decimal) {
                    let [ wn, dc ] = value.split('.')
                    if (dc && dc.length > decimal) {
                        dc      = dc.substr(0, decimal)
                        value   = [ wn, dc ].join('.')
                    }
                }
    
                if (blurred && value) value = (parseFloat(value) / 1).toString()
                if (!value && min) value = min
                
                return { name: target.name, value }
            }
            case "number-string": {
                let value = target.value.replace(/[^\d]/g, "")
                return { name: target.name, value }
            }
            case "exp-date": {
                const parsedValue = target.value.replace(/[^\d]/g, "")
                let value = parsedValue.slice(0, 2)
                if (parsedValue.length > 2) value += '/' + parsedValue.slice(2, 6)

                return { name: target.name, value }
            }
            default: return { name: target.name, value: target.value }
        }
    }

    return (
        <div
            className = {`fth-input ${ className }`}
        >
            <label>
                { label } *
            </label>
            <div>
                <input
                    name        = { name }
                    value       = { value }
                    onChange    = { ({ target }) => onChange(handleChange(target)) }
                    type        = { type === "password" ? type : "text" }
                    onBlur      = { value => {
                                    if (type === "numeric") onChange(handleChange({ name, value }, true))
                                }}
                />
            </div>
            <div
                className="text-danger"
                style={{ fontSize: 12, fontWeight: "bold" }}
            >
                { error }
            </div>
        </div>
    )
}