import { useState, useEffect } from 'react'
import { FooterCTAs, Header, Methods, PaymentInfo, SuccessPage } from './components'
import './_styles.scss'
import FormContext from './form.context'

export const RightSection = ({
    step,
    setStep
}) => {
    const [ method, setMethod ]     = useState('')
    const [ form, setForm ]         = useState({})
    const [ cardDetails, setCard ]  = useState({})
    const [ isLoading, setLoading ] = useState(false)

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [ step ])

    return (
        <FormContext.Provider
            value = {{
                form,
                method,
                cardDetails,
                onCardChange: newCard => setCard(newCard),
                onFormChange: newForm => setForm(newForm),
                step,
                setStep,
                isLoading,
                setLoading
            }}
        >
            <div className={`right-section-wrapper ${ step === 4 ? "success" : '' }`}>
                <div className='payment-form'>
                    <Header show = { step < 4 } />
                    <PaymentInfo
                        show = { step === 2 || step === 3 }
                    />
                    <Methods
                        show = { step === 1 }
                        onSelect = { value => setMethod(value) }
                        selected = { method }
                    />
                    <SuccessPage show = { step === 4 } />
                    <FooterCTAs
                        onLeftClick     = {() => {
                                            setStep(prev => prev - 1)
                                        }}
                        onRightClick    = {() => {
                                            setStep(prev => prev + 1)
                                        }}
                        disabled        = { !method }
                        method          = { method }
                        step            = { step }
                    />
                </div>
            </div>
            {
                isLoading ? (
                    <div className='loading-overlay'>
                        <img
                            src = { require("./loader.gif") }
                            alt = "loader"
                        />
                    </div>
                ) : null
            }
        </FormContext.Provider>
    )
}