import { useContext } from "react"
import FormContext from "../../../../../form.context"
import { connect } from "../api.utils"

export const BankDepositButton = () => {
    const { form, setStep, isLoading, setLoading} = useContext(FormContext)
    
    return (
        <button
            className   = "right-btn"
            disabled    = { isLoading || !Object.keys(form).length }
            onClick     = { () => {
                            const date  = form.date_deposit || new Date()
                            const month = date.getMonth() + 1
                            const day = date.getDate()
                            const depositDate = `${ date.getFullYear() }-${ month < 10 ? '0' : '' }${ month }-${ day < 10 ? '0' : '' }${ day }`
                            const newForm = new FormData()
                            Object.keys(form).forEach(key => {
                                newForm.append(key, form[key])
                            })
                            newForm.append("reference_number", `${ form.account_number }-${ Date.now() }`)
                            newForm.append("payment_type", "bank_deposit")
                            newForm.append("date_deposit", depositDate)

                            setLoading(true)
                            connect().post("/api/transaction", newForm)
                                .then(() => setStep(4))
                                .catch(() => alert("Transaction was unsuccessful. Please try again."))
                                .finally(() => setLoading(false))
                        } }
        >
            Proceed
        </button>
    )
}