import './_styles.scss'

export const Methods = ({
    show,
    selected,
    onSelect = () => {}
}) => {

    return show ? (
        <div className="methods">
            <div className="method-header">
                Choose your prefered payment method
            </div>
            <div
                className='method-card d-flex justify-content-between align-items-center'
                onClick = { () => onSelect('paypal') }
            >
                <img
                    alt = ""
                    src = { require('./paypal-logo.png') }
                    className = "method-logo"
                />
                <div>
                    <img
                        alt = ""
                        className = { `method-selected ${ selected === "paypal" ? "show" : '' }` }
                        src = { require('./check.png') }
                    />
                </div>
            </div>
            <div
                className='method-card d-flex justify-content-between align-items-center'
                onClick = { () => onSelect('global') }
            >
                <img
                    alt = ""
                    src = { require('./gp.jpg') }
                    className = "method-logo"
                />
                <div>
                    <img
                        alt = ""
                        className = { `method-selected ${ selected === "global" ? "show" : '' }` }
                        src = { require('./check.png') }
                    />
                </div>
            </div>
            <div
                className='method-card d-flex justify-content-between align-items-center'
                onClick={ () => onSelect("bank") }
            >
                <div className='method-header'>
                    Bank Deposit
                </div>
                <div>
                    <img
                        alt = ""
                        className = { `method-selected ${ selected === "bank" ? "show" : '' }` }
                        src = { require('./check.png') }
                    />
                </div>
            </div>
        </div>
    ) : null
}