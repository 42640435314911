import { BankDepositButton, GlobalPaymentsButton, PaypalButton } from "./components"

export const PaymentButtons = ({
    method
}) => {

    switch (method) {
        case "paypal": return <PaypalButton />
        case "bank": return <BankDepositButton />
        default: return <GlobalPaymentsButton />
    }
}