import axios from 'axios'

export function connect(additionalHeaders = {}){
	let instance
	let headers = Object.assign({'Access-Control-Allow-Origin': '*'}, additionalHeaders)

	instance = axios.create({
        headers,
        baseURL: process.env.REACT_APP_API_URL
    })

	instance.interceptors.response.use(function (response) {
		return response.data
	}, function (error) {
		const {response} = error
		
		return Promise.reject(response)
	})
	return instance
}