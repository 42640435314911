import './_styles.scss'
import bg from './bg.jpg'

export const LeftSection = ({ show }) => {
    const year = new Date().getFullYear()

    return show ? (
        <div className='d-flex left-section-wrapper'>
            <img
                src = { bg }
                alt = ""
                className = "wrapper-bg"
            />
            <div className='left-text-content'>
                <h1>
                    Lorem Ipsum
                </h1>
                <div>
                    Aenean luctus rhoncus elit eu ornare. Curabitur non lacus nec risus pretium porttitor.
                    Nulla id ligula hendrerit, condimentum orci vitae, molestie sem.
                    Curabitur luctus enim mi. Sed accumsan quam ut ligula semper, id vestibulum velit
                    consectetur. Morbi id lacus metus. Curabitur sed porta risus.
                </div>
            </div>
            <div className='copy-rights'>
                <img
                    alt = "logo"
                    src = { require('./faith_logo.png') }
                    className = "f-logo"
                />
                <div className='txt-center'>
                    Copyright © { year } Faith Academy
                </div>
            </div>
        </div>
    ) : null
}