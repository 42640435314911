import { useContext } from "react"
import FormContext from "../../../../../form.context"
import { connect } from "../api.utils"

export const GlobalPaymentsButton = () => {
    const { form, cardDetails, setStep, step, setLoading, isLoading } = useContext(FormContext)
    
    return (
        <button
            className   = "right-btn"
            disabled    = { isLoading || !Object.keys(step === 2 ? form : cardDetails).length }
            onClick     = { () => {
                            if (step === 2) setStep(3)
                            else {
                                const [ card_expiration_month, card_expiration_year ] = cardDetails.expiration_date.split('/')
                                const newForm = { ...form, ...cardDetails, card_expiration_month, card_expiration_year }

                                delete newForm.expiration_date
                                newForm.payment_type = "globalpayments"

                                setLoading(true)
                                connect().post("/api/transaction", newForm)
                                    .then(() => setStep(4))
                                    .catch(() => alert("Transaction was unsuccessful. Please try again."))
                                    .finally(() => setLoading(false))
                            }
                        } }
        >
            Proceed
        </button>
    )
}