function formRules (data = {}) {
    return {
        properties: {
            name: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            account_number: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            email: {
                type: 'string',
                format: "email",
                required: true,
                allowEmpty: false
            },
            contact_number: {
                type: ['string', 'number'],
                required: true,
                allowEmpty: false
            },
            amount: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            grade: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            ...(
                data.payment_type === "Other" ? {
                    payment_details: {
                        type: "string",
                        required: true,
                        allowEmpty: false
                    }
                } : {}
            ),
            ...(
                data.method === "bank" ? {
                    payee_name: {
                        type: "string",
                        required: true,
                        allowEmpty: false
                    },
                    attachment: {
                        type: "object",
                        required: true,
                        allowEmpty: false
                    }
                } : {}
            )
        }
    }
}

export default formRules