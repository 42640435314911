function formRules (data = {}) {
    return {
        properties: {
            card_number: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            expiration_date: {
                type: 'string',
                required: true,
                allowEmpty: false,
                minLength: 7,
                messages: {
                    minLength: "is invalid"
                }
            },
            card_security_code: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            biller_first_name: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            biller_last_name: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            biller_address1: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            biller_city: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            biller_country: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            biller_postal_code: {
                type: 'string',
                required: true,
                allowEmpty: false
            }
        }
    }
}

export default formRules